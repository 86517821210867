export const environment = {
	env: 'dev',
	apiUrl:'https://hbr-hm-api-gateway-service-prod.105fe103emdu.eu-gb.codeengine.appdomain.cloud/observations/api/v1/',
	loginUrl:'https://hbr-hm-api-gateway-service-prod.105fe103emdu.eu-gb.codeengine.appdomain.cloud/observations/api/v1/',
	apiKey:'fd1b5965-b9b2-4302-a6a0-b7a39db3fcfd',
							
	expectedEndPointIndicator:'7',
	astro_api:'https://hbr-hm-api-gateway-service-prod.105fe103emdu.eu-gb.codeengine.appdomain.cloud/astro/api/v1/',
							
	astro_api_clientId:'mock',
	osr_api:'https://hbr-hm-api-gateway-service-prod.105fe103emdu.eu-gb.codeengine.appdomain.cloud/osr/api/v1/',
	osr_api_clientId:'fd1b5965-b9b2-4302-a6a0-b7a39db3fcfd',
							
	mapPortalId:'db94cd0055024a558250c2eaa73f5d71',
	localUrl:'',
	isLogin: false,
							
	apiAuthUrl: 'https://hbr-hm-auth-helper-service-prod.105fe103emdu.eu-gb.codeengine.appdomain.cloud/api/v1/auth/token',
              
	activeDirectoryClientId:'58949c38-857a-40b3-be4e-a28013b942d4',
	activeDirectoryTenantId:'30453998-4784-4b0e-bdb0-a8ba14eff494',
							
	activeDirectoryRedirectUrl:'https://hydrometeo.naiade.portofrotterdam.com/auth',
	activeDirectoryResource:'http://customappsso/4133c5d6-3b60-46c9-b4f4-9100bce38137',
              
	title:'Aeolus 2.5.1', 
	rws_api:'https://hbr-hm-api-gateway-service-prod.105fe103emdu.eu-gb.codeengine.appdomain.cloud/rws/api/v1/',
	rws_api_clientId:'fd1b5965-b9b2-4302-a6a0-b7a39db3fcfd',
                                                        
	watsonUrl:'https://aeo4jv.internetofthings.ibmcloud.com/dashboard/#/devices/browse-v2',
	naiadeMapUrl:'https://portofrotterdam.maps.arcgis.com/apps/webappviewer/index.html?id=5a58c4ef3cb04910b7ae4d273b7a1cee',
	alertsUrl:'https://ibmbmmgmt.eu-gb.mybluemix.net/index?subscriptionId=25af935c-1482-4355-b599-c03b545a2408&dashboard=ans.dashboard.alertviewer'
};
